<template>
  <div></div>
</template>
<script>
export default {
  name: 'WhitelistEmailDeposit',

  data() {
    return {
      input:{
        email:'',
        code:''
      },
    }
  },
  mounted:function(){
    this.whitelistEmail();
  },
  methods:{
    whitelistEmail() {
      this.input.email = this.$route.query.email;
      this.input.code = this.$route.query.token;
      this.$http.post(this.createApiUrlChestNut('whitelisted-deposit-addresses/verify/email'), this.input).then(response =>
        {
          let walletData = response.body
          if(!walletData.isVasp){
            window.location = '/dashboard/#/address?id='+ walletData.id + '&action=deposit'
          }
          else{
            window.location = '/dashboard/#/address?action=deposit'
          }
        },() => {
          this.$router.push({ name: 'Whitelist Expired', params: {pageType: 'Deposit'}}).catch(() => {});
        });
    },
  },
}
</script>
